import React from 'react';

import Header from '../../components/Global/Header';
import Sidebar from '../../components/Admin/Sidebar';

import { ManagementMiles } from './ListOfOptionsForModuleType/ManagementMiles';
import { ManagementPPR } from './ListOfOptionsForModuleType/ManagementPPR';
// import { OrderServices } from './ListOfOptionsForModuleType/OrderServices';
import { Rules } from './ListOfOptionsForModuleType/Rules';

import { HeaderUp } from '../../components/_components_0.2/Header_0.2';

interface LayoutProps {
  children: React.HTMLAttributes<HTMLElement>;
  path: string;
}

const LayoutAdm: React.FC<LayoutProps> = ({ children, path }: LayoutProps) => {
  if (path === '/menu') {
    return (
      <>
        {/* <Header>
          <span>{path}</span>
        </Header> */}
        <HeaderUp path="home" />
        {children}
      </>
    );
  }

  if (path.substring(0, 23) === '/performance-evaluation') {
    return (
      <>
        <HeaderUp path="home" />
        {children}
      </>
    );
  }
  if (path.substring(0, 24) === '/report-income-statement') {
    return (
      <>
        <HeaderUp path="home" />
        {children}
      </>
    );
  }
  if (path.substring(0, 3) === '/ia') {
    return <>{children}</>;
  }
  if (path.substring(0, 5) === '/test') {
    return <>{children}</>;
  }
  if (path.substring(0, 4) === '/pic') {
    return <>{children}</>;
  }
  if (path.substring(0, 6) === '/miles') {
    return <>{children}</>;
  }
  if (path.substring(0, 14) === '/bi-management') {
    return <>{children}</>;
  }
  if (path.substring(0, 14) === '/profile') {
    return <>{children}</>;
  }
  if (path.substring(0, 18) === '/management-energy') {
    return <>{children}</>;
  }
  if (path.substring(0, 28) === '/climate-research-management') {
    return <>{children}</>;
  }
  if (path.substring(0, 19) === '/process-management') {
    return <>{children}</>;
  }
  if (path.substring(0, 9) === '/schedule') {
    return <>{children}</>;
  }
  if (path.substring(0, 19) === '/strategic-planning') {
    return (
      <>
        <HeaderUp path="home" />
        {children}
      </>
    );
  }
  if (path.substring(0, 11) === '/indicators') {
    return (
      <>
        <HeaderUp path="home" />
        {children}
      </>
    );
  }
  if (path.substring(0, 15) === '/management-ppr') {
    return (
      <>
        <Header>
          <span>{path}</span>
        </Header>
        <Sidebar pathname={path}>
          <ManagementPPR pathname={path} />
        </Sidebar>
        {children}
      </>
    );
  }

  if (path.substring(0, 15) === '/service-orders') {
    return <>{children}</>;
  }

  if (path.substring(0, 14) === '/administrator') {
    return (
      <>
        {/* <Addministrator pathname={path} /> */}

        {children}
      </>
    );
  }

  if (path.substring(0, 11) === '/your-miles') {
    return (
      <>
        <Header>
          <span>{path}</span>
        </Header>
        <Sidebar pathname={path}>
          <ManagementMiles pathname={path} />
        </Sidebar>
        {children}
      </>
    );
  }
  if (path.substring(0, 6) === '/rules') {
    return (
      <>
        <Header>
          <span>{path}</span>
        </Header>
        <Sidebar pathname={path}>
          <Rules pathname={path} />
        </Sidebar>
        {children}
      </>
    );
  }
  if (path.substring(0, 6) === '/visio') {
    return (
      <>
        <Header>
          <span>{path}</span>
        </Header>
        <Sidebar pathname={path}>
          <Rules pathname={path} />
        </Sidebar>
        {children}
      </>
    );
  }
  if (path.substring(0, 16) === '/file-management') {
    return (
      <>
        <Header>
          <span>{path}</span>
        </Header>
        <Sidebar pathname={path}>
          <Rules pathname={path} />
        </Sidebar>
        {children}
      </>
    );
  }

  return (
    <>
      <Header>
        <span>{path}</span>
      </Header>
    </>
  );
};

export default LayoutAdm;
