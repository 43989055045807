/* eslint-disable no-constant-condition */
/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {
  Box,
  Center,
  SimpleGrid,
  useBreakpointValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ScaleFade,
} from '@chakra-ui/react';
import { useAuth } from '../../../hooks/auth';

import { ContainerCard, Content } from './styles';

import destin from '../../../assets/destin.svg';
import pie from '../../../assets/pie.svg';
import os from '../../../assets/os.svg';
import fluxo from '../../../assets/fluxo.svg';
import adm from '../../../assets/adm.svg';
import folders from '../../../assets/folders.png';
import pic from '../../../assets/pic.svg';
import growth from '../../../assets/growth.png';
import lucro from '../../../assets/lucro.png';
import pacientes from '../../../assets/pacientes.png';
// import book from '../../../assets/book.png';
import { SetToggleThemeContext } from '../../../contexts/SetToggleThemeContext';
import { useCan } from '../../../hooks/useCan';
import { api } from '../../../services/api';

const MenuList: React.FC = () => {
  const [userDataUpdate, setUserDataUpdate] = useState(false);
  const [userViewPerformanceEvaluation, setUserViewPerformanceEvaluation] =
    useState(false);
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { user } = useAuth();
  const { toggleTheme } = useContext(SetToggleThemeContext);

  const userCanSeeFileManager = useCan({
    permissions: ['file_manager'],
  });
  const userCanSeeStrategicPlanning = useCan({
    permissions: ['strategic_planning'],
  });
  const userCanSeePIC = useCan({
    permissions: ['pic_access'],
  });
  const userCanSeeOrderServiceData = useCan({
    permissions: ['user_orderServices_data'],
  });
  const userCanViewPerformanceEvaluation = useCan({
    permissions: ['user_wiew_performance_evaluation'],
  });

  useEffect(() => {
    try {
      api
        .get(
          `/hierarchies/filter?take=999&page=1&leader=${user.nickname}&year=2023`,
        )
        .then(() => {
          if (userCanViewPerformanceEvaluation) {
            setUserViewPerformanceEvaluation(true);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }, [user.nickname, userCanViewPerformanceEvaluation]);

  useEffect(() => {
    if (!user.senior_id) {
      setUserDataUpdate(true);
    }
  }, [user.senior_id]);

  return (
    <>
      {userDataUpdate && (
        <ScaleFade initialScale={0.9} in>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Seus dados estão desatualizados!</AlertTitle>
            <AlertDescription>
              <Link to="/profile">Saber mais</Link>
            </AlertDescription>
          </Alert>
        </ScaleFade>
      )}

      <ContainerCard theme={toggleTheme}>
        <Center mt="-40">
          <SimpleGrid
            flex="1"
            columns={[2, null, 4]}
            // columns={{ sm: 2, md: 4 }}
            spacing={5}
            // minChildWidth={['370px', '400', '420px']}
            alignItems="flex-start"
            mt={isWideVersion ? '0' : '10rem'}
          >
            <Box m="5px">
              <Link to="/report-income-statement">
                <Content>
                  <img
                    src={lucro}
                    className="lucro"
                    alt="Participação nos lucros"
                  />
                  <strong>Gestão a vista</strong>
                </Content>
              </Link>
            </Box>
            <Box m="5px">
              <Link to="/miles/dashboard">
                <Content>
                  <img src={destin} alt="imagem de destino" />
                  <strong>Controle de frota</strong>
                </Content>
              </Link>
            </Box>

            <Box m="5px">
              {/* <Link to="/management-ppr/dashboard"> */}
              <Link to="/bi-management/dashboard">
                <Content>
                  <img src={pie} alt="imagem de grafico pizza" />
                  {/* <strong>Gerenciamento PPR</strong> */}
                  <strong>Inteligência de negócios</strong>
                </Content>
              </Link>
            </Box>

            {/* <div className={user.tag === 'admin' ? '' : 'disabled'}> */}
            <Box m="5px">
              <Link
                to={
                  userCanSeeOrderServiceData
                    ? '/service-orders-ti/painel'
                    : '/service-orders-ti/user'
                }
              >
                <Content>
                  <img src={os} alt="imagem de ordem de serviço" />
                  <strong>Ordens de serviço</strong>
                </Content>
              </Link>
            </Box>
            {/* </div> */}

            {/* <Box className={user.tag === 'admin' ? '' : 'disabled'} m="5px"> */}
            <Box m="5px">
              <Link to="/rules">
                <Content>
                  <img src={fluxo} alt="imagem de fluxo grama" />
                  <strong>Normas e manuais</strong>
                </Content>
              </Link>
            </Box>

            {userCanSeeFileManager && (
              <Box m="5px">
                <Link to="/file-management">
                  <Content>
                    <img src={folders} alt="imagem de fluxo grama" />
                    <strong>Gerenciador de Arquivos</strong>
                  </Content>
                </Link>
              </Box>
            )}
            {userCanSeePIC && (
              <Box m="5px">
                <Link to="/pic">
                  <Content>
                    <img src={pic} alt="imagem de fluxo grama" />
                    <strong>Plano de invest. Cooasgo</strong>
                  </Content>
                </Link>
              </Box>
            )}

            {userCanSeeStrategicPlanning && (
              <Box m="5px">
                <Link to="/strategic-planning">
                  <Content>
                    <img
                      src={growth}
                      alt="imagem de planejamento estratégico"
                    />
                    <strong>Planejamento estratégico</strong>
                  </Content>
                </Link>
              </Box>
            )}
            {userViewPerformanceEvaluation && (
              <Box m="5px">
                <Link to="/performance-evaluation/home">
                  <Content>
                    <img
                      src={pacientes}
                      className="pacientes"
                      alt="imagem de avaliação de desempenho"
                    />
                    <strong>Avaliação de desempenho</strong>
                  </Content>
                </Link>
              </Box>
            )}

            {/* <Box m="5px">
            <Link to="/schedule">
              <Content>
                <img src={book} alt="imagem de agenda" />
                <strong>Agenda Cooasgo</strong>
              </Content>
            </Link>
          </Box> */}

            {/* </Box> */}
            <Box className={user.tag === 'admin' ? '' : 'disabled'} m="5px">
              <Link to="/administrator/employers">
                <Content>
                  <img src={adm} alt="imagem de administrador" />
                  <strong>Administrador</strong>
                </Content>
              </Link>
            </Box>
          </SimpleGrid>
        </Center>
      </ContainerCard>
    </>
  );
};

export default MenuList;
